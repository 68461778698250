// Import package from Soil plugin example.
import $ from "@modules/jquery";

$(function() {

    let $menuMobile = $("#dropdown-mobile-mobile");
    let $accordionMenu = $menuMobile.find(".accordion-menu");

    // Initially hide the accordion menu
    $accordionMenu.hide();

    // Event handler for click events on links within the dropdown menu
    $accordionMenu.find("li a").on("click", function () {
        $accordionMenu.hide();
        // When hiding the menu, apply the hide class
        $menuMobile.removeClass('menu-mobile-show').addClass('menu-mobile-hide');
    });

    // Event handler for sticky behavior
    $('[data-sticky]').on('sticky.zf.stuckto:top', function () {
        $menuMobile.addClass('dropdown-sticky');
    });

    $('[data-sticky]').on('sticky.zf.unstuckfrom:top', function () {
        $menuMobile.removeClass('dropdown-sticky');
    });

    $(".menu-mobile-toggle").on("click", function () {
        if ($accordionMenu.css('display') === 'none') {
            // If the menu is hidden, show it and apply the show class
            $accordionMenu.show();
            $menuMobile.removeClass('menu-mobile-hide').addClass('menu-mobile-show');
        } else {
            // If the menu is visible, hide it and apply the hide class
            $accordionMenu.hide();
            $menuMobile.removeClass('menu-mobile-show').addClass('menu-mobile-hide');
        }
    });
    // changed column order
    const mediaQuery = setInterval(() => {
        if (Foundation.MediaQuery.queries.length === 0) {
            return;
        }

        if (Foundation.MediaQuery.current !== "") {
            clearInterval(mediaQuery);
        }

        if (Foundation.MediaQuery.upTo("md")) {
            console.log('md 28')
            const columns = document.querySelectorAll(".wp-block-column");
            columns.forEach((column) => {
                column.style.order = "1";
                if (column.querySelectorAll(".wp-block-cover, .wp-block-image").length > 0) {
                    column.style.order = "2";
                }
            });
        }
    });

    $(window).on("changed.zf.mediaquery", function (event, newSize, oldSize) {
        if (Foundation.MediaQuery.queries.length === 0) {
            return;
        }
        if (Foundation.MediaQuery.upTo("md")) {
            const columns = document.querySelectorAll(".wp-block-column");
            console.log('md 45')
            columns.forEach((column) => {
                column.style.order = "1";
                if (
                    column.querySelectorAll(".wp-block-cover, .wp-block-image").length > 0
                ) {
                    column.style.order = "2";
                }
            });
        }
        else {
            const columns = document.querySelectorAll(".wp-block-column");
            columns.forEach((column) => {
                column.style.order = "1";
            });
        }
    });
});
